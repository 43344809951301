module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"wrapperStyle":"border: 1px solid;","maxWidth":1200}},{"resolve":"gatsby-remark-table-of-contents","options":{"exclude":"Table of Contents","tight":false,"fromHeading":1,"toHeading":6}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{},"showLineNumbers":false,"noInlineHighlight":false}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}}],"defaultLayouts":{"default":"/opt/build/repo/src/components/layout.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-default-mdx-basic","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"edf3d310d67f8284a562bc3a58c3e761"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
