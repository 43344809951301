// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-date-time-mdx": () => import("./../src/pages/date-time.mdx" /* webpackChunkName: "component---src-pages-date-time-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-scalars-guide-mdx": () => import("./../src/pages/scalars-guide.mdx" /* webpackChunkName: "component---src-pages-scalars-guide-mdx" */)
}

